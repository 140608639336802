import {
  BRACELET_GET_METRICS,
  BRACELET_SET_LOADING,
  BRACELET_ERROR,
  BRACELET_CLEAR_ERRORS,
  BRACELET_CLEAR_DATA,
} from "../types";

export default function braceletReducer(state, action) {
  switch (action.type) {
    case BRACELET_GET_METRICS:
      return {
        ...state,
        metrics: action.payload,
        errors: null,
        loading: false,
      };
    case BRACELET_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case BRACELET_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case BRACELET_CLEAR_ERRORS:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case BRACELET_CLEAR_DATA:
      return {
        ...state,
        metrics: {},
        loading: false,
        errors: null,
      };
    default:
      return state;
  }
}