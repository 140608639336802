import React, { useReducer } from 'react';
import axios from 'axios';
import BraceletContext from './braceletContext';
import braceletReducer from './braceletReducer';
import {
  BRACELET_GET_METRICS,
  BRACELET_SET_LOADING,
  BRACELET_ERROR,
  BRACELET_CLEAR_ERRORS,
  BRACELET_CLEAR_DATA,
} from '../types';

const BraceletState = (props) => {
  const initialState = {
    metrics: {},
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(braceletReducer, initialState);

  // Fetch bracelet metrics
  const fetchBraceletMetrics = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/bracelet-proof/metrics');
      dispatch({
        type: BRACELET_GET_METRICS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: BRACELET_ERROR,
        payload: err.response?.data?.errors || 'Error fetching data',
      });
    }
  };

  // Set Loading
  const setLoading = () => dispatch({ type: BRACELET_SET_LOADING });

  // Clear Errors
  const clearErrors = () => dispatch({ type: BRACELET_CLEAR_ERRORS });

  // Clear Data
  const clearBraceletData = () => dispatch({ type: BRACELET_CLEAR_DATA });

  return (
    <BraceletContext.Provider
      value={{
        metrics: state.metrics,
        loading: state.loading,
        errors: state.errors,
        fetchBraceletMetrics,
        clearErrors,
        clearBraceletData,
      }}
    >
      {props.children}
    </BraceletContext.Provider>
  );
};

export default BraceletState;