import React, { useReducer } from 'react';
import axios from 'axios';
import RoleAccessContext from './roleAccessContext';
import { roleAccessReducer } from './roleAccessReducer';
import {
  ROLE_ACCESS_LOAD_ACCESS,
  ROLE_ACCESS_ERROR,
  ROLE_ACCESS_CLEAR_DATA,
} from '../types';

const RoleAccessState = (props) => {
  const initialState = {
    access: [],
    loading: true,
    errors: null,
  };

  const [state, dispatch] = useReducer(roleAccessReducer, initialState);

  // Load Role Access
  const loadRoleAccess = async (userId) => {
    try {
      const res = await axios.get(`/api/v1/access/${userId}`);
      dispatch({
        type: ROLE_ACCESS_LOAD_ACCESS,
        payload: res.data.data,
      });
    } catch (errors) {
      dispatch({
        type: ROLE_ACCESS_ERROR,
        payload: errors?.response?.data?.errors
      });
    }
  };

  // Clear Data
  const clearRoleAccessData = () => dispatch({ type: ROLE_ACCESS_CLEAR_DATA });

  return (
    <RoleAccessContext.Provider
      value={{
        access: state.access,
        loading: state.loading,
        errors: state.errors,
        loadRoleAccess,
        clearRoleAccessData,
      }}
    >
      {props.children}
    </RoleAccessContext.Provider>
  );
};

export default RoleAccessState;