import React, { useReducer } from 'react';
import axios from 'axios';
import { configJSON } from '../../config/requestConfig';
import PreventionContext from './preventionContext';
import preventionReducer from './preventionReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import {
  GET_PREVENTIONS,
  GET_PREVENTION,
  SET_LOADING_PREVENTION,
  PREVENTION_ERROR,
  CLEAR_ERRORS_PREVENTION,
  CLEAR_DATA_PREVENTION,
} from '../types';

const PreventionState = (props) => {
  const initialState = {
    preventions: [],
    prevention: {},
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(preventionReducer, initialState);

  // Fetch all preventions
  const getPreventions = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/preventions');
      dispatch({
        type: GET_PREVENTIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PREVENTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all preventions with published true
  const getPreventionsWithPublishedTrue = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/preventions/published/');
      dispatch({
        type: GET_PREVENTIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PREVENTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all preventions with published true and current item
  const getPreventionsWithPublishedTrueAndCurrentItem = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/preventions/published/${id}`);
      dispatch({
        type: GET_PREVENTIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PREVENTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch single prevention
  const getPrevention = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/preventions/${id}`);
      dispatch({
        type: GET_PREVENTION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PREVENTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Create a new prevention
  const createPrevention = async (
    formData,
    formDataFile,
    history,
    enqueueSnackbar
  ) => {
    try {
      setLoading();
      const res = await axios.post(`/api/v1/preventions`, formData, configJSON);
      if (formDataFile.get('image') instanceof File && res.data?.data?._id) {
        await uploadFile(formDataFile, res.data.data._id);
      }
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/preventions');
    } catch (error) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: PREVENTION_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update prevention
  const updatePrevention = async (
    id,
    formData,
    formDataFile,
    history,
    enqueueSnackbar
  ) => {
    try {
      setLoading();
      const res = await axios.put(
        `/api/v1/preventions/${id}`,
        formData,
        configJSON
      );
      if (formDataFile.get('image') instanceof File && res.data?.data?._id) {
        await uploadFile(formDataFile, res.data.data._id);
      }
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/preventions');
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: PREVENTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Upload file
  const uploadFile = async (formDataFile, id) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      setLoading();
      // Return path file uploaded
      await axios.post(
        `/api/v1/uploads/preventions/${id}`,
        formDataFile,
        config
      );
    } catch (error) {
      dispatch({
        type: PREVENTION_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update published prevention
  const updatePublishedPrevention = async (id, formData, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(
        `/api/v1/preventions/${id}/published`,
        formData,
        configJSON
      );
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: PREVENTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Delete prevention
  const deletePrevention = async (id, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.delete(`/api/v1/preventions/${id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: PREVENTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_PREVENTION,
    });
  };

  // Clear errors
  const clearErrorsPrevention = async () => {
    dispatch({
      type: CLEAR_ERRORS_PREVENTION,
    });
  };

  // Clear data
  const clearDataPrevention = async () => {
    dispatch({
      type: CLEAR_DATA_PREVENTION,
    });
  };

  return (
    <PreventionContext.Provider
      value={{
        preventions: state.preventions,
        prevention: state.prevention,
        errors: state.errors,
        loading: state.loading,
        getPreventions,
        getPreventionsWithPublishedTrue,
        getPreventionsWithPublishedTrueAndCurrentItem,
        getPrevention,
        createPrevention,
        updatePrevention,
        updatePublishedPrevention,
        deletePrevention,
        clearErrorsPrevention,
        clearDataPrevention,
      }}
    >
      {props.children}
    </PreventionContext.Provider>
  );
};

export default PreventionState;
