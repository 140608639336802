import React, { useReducer } from 'react';
import axios from 'axios';
import UserProcessContext from './userProcessContext';
import userProcessReducer from './userProcessReducer';
import {
  GET_USER_PROCESS,
  SET_LOADING_USER_PROCESS,
  USER_PROCESS_ERROR,
  CLEAR_DATA_USER_PROCESS,
} from '../types';

const UserProcessState = (props) => {
  const initialState = {
    process: {},
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(userProcessReducer, initialState);

  // Fetch process by current user
  const getProcessByCurrentUser = async () => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/processes/currentuser`);
      dispatch({
        type: GET_USER_PROCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_PROCESS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_USER_PROCESS,
    });
  };

  // Clear data
  const clearDataUserProcess = async () => {
    dispatch({
      type: CLEAR_DATA_USER_PROCESS,
    });
  };

  return (
    <UserProcessContext.Provider
      value={{
        process: state.process,
        errors: state.errors,
        loading: state.loading,
        getProcessByCurrentUser,
        clearDataUserProcess,
      }}
    >
      {props.children}
    </UserProcessContext.Provider>
  );
};

export default UserProcessState;
