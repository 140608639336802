import {
  ROLE_ACCESS_LOAD_ACCESS,
  ROLE_ACCESS_ERROR,
  ROLE_ACCESS_CLEAR_DATA
} from "../types";

export const roleAccessReducer = (state, action) => {
  switch (action.type) {
    case ROLE_ACCESS_LOAD_ACCESS:
      return {
        ...state,
        access: action.payload,
        loading: false,
        errors: null,
      };
    case ROLE_ACCESS_ERROR:
      return {
        ...state,
        access: [],
        loading: false,
        errors: action.payload,
      };
    case ROLE_ACCESS_CLEAR_DATA:
      return {
        access: [],
        loading: true,
        errors: null,
      };
    default:
      return state;
  }
};