import React, { useReducer } from 'react';
import axios from 'axios';
import DepartmentContext from './departmentContext';
import departmentReducer from './departmentReducer';
import {
  GET_DEPARTMENTS,
  SET_LOADING_DEPARTMENT,
  DEPARTMENT_ERROR,
  CLEAR_ERRORS_DEPARTMENT,
  CLEAR_DATA_DEPARTMENT,
} from '../types';

const DepartmentState = (props) => {
  const initialState = {
    departments: [],
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(departmentReducer, initialState);

  // Fetch all departments
  const getDepartments = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/departments');
      dispatch({
        type: GET_DEPARTMENTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: DEPARTMENT_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all departments with state true and current item
  const getDepartmentsWithStateTrueAndCurrentItem = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/departments/state/${id}`);
      dispatch({
        type: GET_DEPARTMENTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: DEPARTMENT_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all departments in process user
  const getDepartmentsByOperator = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/departments/operator');
      dispatch({
        type: GET_DEPARTMENTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: DEPARTMENT_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_DEPARTMENT,
    });
  };

  // Clear errors
  const clearErrorsDepartment = async () => {
    dispatch({
      type: CLEAR_ERRORS_DEPARTMENT,
    });
  };

  // Clear data
  const clearDataDepartment = async () => {
    dispatch({
      type: CLEAR_DATA_DEPARTMENT,
    });
  };

  return (
    <DepartmentContext.Provider
      value={{
        departments: state.departments,
        errors: state.errors,
        loading: state.loading,
        getDepartments,
        getDepartmentsWithStateTrueAndCurrentItem,
        clearErrorsDepartment,
        clearDataDepartment,
        getDepartmentsByOperator,
      }}
    >
      {props.children}
    </DepartmentContext.Provider>
  );
};

export default DepartmentState;
