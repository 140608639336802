import React, { useReducer } from 'react';
import axios from 'axios';
import { configJSON } from '../../config/requestConfig';
import LawContext from './lawContext';
import lawReducer from './lawReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import {
  GET_LAWS,
  GET_LAW,
  SET_LOADING_LAW,
  LAW_ERROR,
  CLEAR_ERRORS_LAW,
  CLEAR_DATA_LAW,
} from '../types';

const LawState = (props) => {
  const initialState = {
    laws: [],
    law: {},
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(lawReducer, initialState);

  // Fetch all laws
  const getLaws = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/laws');
      dispatch({
        type: GET_LAWS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: LAW_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all laws with published true
  const getLawsWithPublishedTrue = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/laws/published');
      dispatch({
        type: GET_LAWS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: LAW_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all laws with published true and current item
  const getLawsWithPublishedTrueAndCurrentItem = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/laws/published/${id}`);
      dispatch({
        type: GET_LAWS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: LAW_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch single law
  const getLaw = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/laws/${id}`);
      dispatch({
        type: GET_LAW,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: LAW_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Create a new law
  const createLaw = async (
    formData,
    formDataFile,
    history,
    enqueueSnackbar
  ) => {
    try {
      setLoading();
      const res = await axios.post(`/api/v1/laws`, formData, configJSON);
      if (formDataFile.get('image') instanceof File && res.data?.data?._id) {
        await uploadFile(formDataFile, res.data.data._id);
      }
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/laws');
    } catch (error) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: LAW_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update law
  const updateLaw = async (
    id,
    formData,
    formDataFile,
    history,
    enqueueSnackbar
  ) => {
    try {
      setLoading();
      const res = await axios.put(`/api/v1/laws/${id}`, formData, configJSON);
      if (formDataFile.get('image') instanceof File && res.data?.data?._id) {
        await uploadFile(formDataFile, res.data.data._id);
      }
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/laws');
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: LAW_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Upload file
  const uploadFile = async (formDataFile, id) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      setLoading();
      // Return path file uploaded
      await axios.post(`/api/v1/uploads/laws/${id}`, formDataFile, config);
    } catch (error) {
      dispatch({
        type: LAW_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update published law
  const updatePublishedLaw = async (id, formData, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(`/api/v1/laws/${id}/published`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: LAW_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Delete law
  const deleteLaw = async (id, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.delete(`/api/v1/laws/${id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: LAW_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_LAW,
    });
  };

  // Clear errors
  const clearErrorsLaw = async () => {
    dispatch({
      type: CLEAR_ERRORS_LAW,
    });
  };

  // Clear data
  const clearDataLaw = async () => {
    dispatch({
      type: CLEAR_DATA_LAW,
    });
  };

  return (
    <LawContext.Provider
      value={{
        laws: state.laws,
        law: state.law,
        errors: state.errors,
        loading: state.loading,
        getLaws,
        getLawsWithPublishedTrue,
        getLawsWithPublishedTrueAndCurrentItem,
        getLaw,
        createLaw,
        updateLaw,
        updatePublishedLaw,
        deleteLaw,
        clearErrorsLaw,
        clearDataLaw,
      }}
    >
      {props.children}
    </LawContext.Provider>
  );
};

export default LawState;
