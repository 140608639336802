import React, { useReducer } from 'react';
import axios from 'axios';
import moment from 'moment';
import DashboardContext from './dashboardContext';
import dashboardReducer from './dashboardReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import {
  GET_ACTIVITIES_DASHBOARD,
  GET_ACTIVITY_DASHBOARD,
  DELETE_ACTIVITY,
  DELETE_VISUALIZATION,
  DELETE_PANIC_BUTTON_PULSATION,
  SET_LOADING_ACTIVITY_DASHBOARD,
  GET_GENDER_DASHBOARD,
  SET_LOADING_GENDER_DASHBOARD,
  GET_APP_INFO_DASHBOARD,
  SET_APP_INFO_LOADING_DASHBOARD,
  GET_USERS_DEPARTMENTS_DASHBOARD,
  SET_USER_DEPARTMENT_LOADING_DASHBOARD,
  GET_USER_MUNICIPALITIES_DASHBOARD,
  SET_USER_MUNICIPALITY_LOADING_DASHBOARD,
  GET_USERS_AGE_DASHBOARD,
  SET_USER_AGE_LOADING_DASHBOARD,
  GET_USER_AGE_MUNICIPALITIES_DASHBOARD,
  SET_USER_AGE_MUNICIPALITY_LOADING_DASHBOARD,
  GET_BTN_PANIC_PULSATIONS_DASHBOARD,
  SET_BTN_PANIC_PULSATIONS_LOADING_DASHBOARD,
  GET_MOST_VIEWED_SCREENS_DASHBOARD,
  SET_MOST_VIEWED_SCREENS_LOADING_DASHBOARD,
  GET_TIME_ACTIVITY_DEPARTMENT_DASHBOARD,
  SET_TIME_ACTIVITY_DEPARTMENT_LOADING_DASHBOARD,
  GET_BTN_PANIC_PULSATIONS_DEPARTMENT_DASHBOARD,
  SET_BTN_PANIC_PULSATIONS_DEPARTMENT_LOADING_DASHBOARD,
  GET_MOST_VIEWED_SCREENS_DEPARTMENT_DASHBOARD,
  SET_MOST_VIEWED_SCREENS_DEPARTMENT_LOADING_DASHBOARD,
  GET_MARKERS_PANIC_BTN_DEPARTMENT_DASHBOARD,
  SET_MARKER_BTN_PANIC_DEPARTMENT_LOADING_DASHBOARD,
  SET_DEPARTMENT_ID,
  SET_START_DATE_DASHBOARD,
  SET_END_DATE_DASHBOARD,
  SET_START_DATE_DEPARTMENT_DASHBOARD,
  SET_END_DATE_DEPARTMENT_DASHBOARD,
  SET_START_DATE_MARKER_MAP_DASHBOARD,
  SET_END_DATE_MARKER_MAP_DASHBOARD,
  DASHBOARD_ERROR,
  CLEAR_ERRORS_DASHBOARD,
  CLEAR_DATA_DASHBOARD,
} from '../types';

const DashboardState = (props) => {
  const initialState = {
    activities: [],
    activity: {},
    activityLoading: true,
    gender: {},
    genderLoading: true,
    app: {},
    appLoading: true,
    userDepartments: [],
    userDepartmentLoading: true,
    userAges: [],
    userAgeLoading: true,
    errors: null,
    userMunicipalities: [],
    userMunicipalityLoading: true,
    userAgeMunicipalities: [],
    userAgeMunicipalityLoading: true,
    btnPanicPulsations: {},
    btnPanicPulsationLoading: true,
    mostViewedScreens: [],
    mostViewedScreenLoading: true,
    timeActivityDepartment: {},
    timeActivityDepartmentLoading: true,
    btnPanicPulsationsDepartment: {},
    btnPanicPulsationDepartmentLoading: true,
    mostViewedScreensDepartment: [],
    mostViewedScreenDepartmentLoading: true,
    markersPanicBtnDepartment: [],
    markerPanicBtnDepartmentLoading: true,
    departmentId: null,
    startDateDashboard: moment('2022-05-14').format('YYYY-MM-DD'),
    endDateDashboard: moment().format('YYYY-MM-DD'),
    startDateDepartment: moment('2022-05-14').format('YYYY-MM-DD'),
    endDateDepartment: moment().format('YYYY-MM-DD'),
    startDateMarkerMap: moment('2022-05-14').format('YYYY-MM-DD'),
    endDateMarkerMap: moment().format('YYYY-MM-DD'),
  };

  const [state, dispatch] = useReducer(dashboardReducer, initialState);

  // Fetch user's activities
  const getActivities = async (startDate, endDate) => {
    try {
      const res = await axios.get(
        `/api/v1/activities?startDate=${startDate}&endDate=${endDate}`
      );
      dispatch({
        type: GET_ACTIVITIES_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch activity
  const getActivity = async (id) => {
    try {
      setActivityLoading();
      const res = await axios.get(`/api/v1/activities/${id}`);
      dispatch({
        type: GET_ACTIVITY_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Delete activity
  const deleteActivity = async (activityId, enqueueSnackbar) => {
    try {
      setActivityLoading();
      await axios.delete(`/api/v1/activities/${activityId}`);
      dispatch({
        type: DELETE_ACTIVITY,
      });
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Delete visualization
  const deleteVisualization = async (
    activityId,
    visualizationId,
    enqueueSnackbar
  ) => {
    try {
      setActivityLoading();
      await axios.delete(
        `/api/v1/activities/${activityId}/visualizations/${visualizationId}`
      );
      dispatch({
        type: DELETE_VISUALIZATION,
      });
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Delete panic button pulsation
  const deletePanicButtonPress = async (
    activityId,
    pulsationId,
    enqueueSnackbar
  ) => {
    try {
      setActivityLoading();
      await axios.delete(
        `/api/v1/activities/btn-panic/${activityId}/pulsations/${pulsationId}`
      );
      dispatch({
        type: DELETE_PANIC_BUTTON_PULSATION,
      });
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch statistics user gender
  const getUserGender = async (startDate, endDate) => {
    try {
      setGenderLoading();
      const res = await axios.get(
        `/api/v1/users/statistics/gender?startDate=${startDate}&endDate=${endDate}`
      );
      dispatch({
        type: GET_GENDER_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch app information downloads
  const getAppInfo = async () => {
    try {
      setAppLoading();
      const res = await axios.get('/api/v1/app/android');
      dispatch({
        type: GET_APP_INFO_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch users departments statistic
  const getUserDepartments = async (startDate, endDate) => {
    try {
      setUserDepartmentLoading();
      const res = await axios.get(
        `/api/v1/users/statistics/departments?startDate=${startDate}&endDate=${endDate}`
      );

      dispatch({
        type: GET_USERS_DEPARTMENTS_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch users age statistic
  const getUserAge = async (startDate, endDate) => {
    try {
      setUserAgeLoading();
      const res = await axios.get(
        `/api/v1/users/statistics/age?startDate=${startDate}&endDate=${endDate}`
      );

      dispatch({
        type: GET_USERS_AGE_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch users municipalities statistic
  const getUserMunicipalities = async (departmentId, startDate, endDate) => {
    try {
      setUserMunicipalitiesLoading();
      const res = await axios.get(
        `/api/v1/users/statistics/departments/${departmentId}/municipalities?startDate=${startDate}&endDate=${endDate}`
      );

      dispatch({
        type: GET_USER_MUNICIPALITIES_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch user age municipalities statistic
  const getUserAgeMunicipalities = async (departmentId, startDate, endDate) => {
    try {
      setUserAgeMunicipalitiesLoading();
      const res = await axios.get(
        `/api/v1/users/statistics/departments/${departmentId}/municipalities/age?startDate=${startDate}&endDate=${endDate}`
      );

      dispatch({
        type: GET_USER_AGE_MUNICIPALITIES_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch button pulsations statistic
  const getBtnPanicPulsations = async (startDate, endDate) => {
    try {
      setBtnPanicPulsationsLoading();
      const res = await axios.get(
        `/api/v1/activities/btn-panic?startDate=${startDate}&endDate=${endDate}`
      );

      dispatch({
        type: GET_BTN_PANIC_PULSATIONS_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch most viewed screens statistic
  const getMostViewedScreens = async (startDate, endDate) => {
    try {
      setMostViewedLoading();
      const res = await axios.get(
        `/api/v1/activities/screens?startDate=${startDate}&endDate=${endDate}`
      );

      dispatch({
        type: GET_MOST_VIEWED_SCREENS_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch user activity time department statistic
  const getTimeActivityByDepartment = async (
    departmentId,
    startDate,
    endDate
  ) => {
    try {
      setTimeActivityDepartmentLoading();
      const res = await axios.get(
        `/api/v1/activities/departments/${departmentId}/time?startDate=${startDate}&endDate=${endDate}`
      );

      dispatch({
        type: GET_TIME_ACTIVITY_DEPARTMENT_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch panic button pulsations department statistic
  const getBtnPanicPulsationsByDepartment = async (
    departmentId,
    startDate,
    endDate
  ) => {
    try {
      setBtnPanicPulsationsDepartmentLoading();
      const res = await axios.get(
        `/api/v1/activities/departments/${departmentId}/btn-panic?startDate=${startDate}&endDate=${endDate}`
      );

      dispatch({
        type: GET_BTN_PANIC_PULSATIONS_DEPARTMENT_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch most viewed screens department statistic
  const getMostViewedScreensByDepartment = async (
    departmentId,
    startDate,
    endDate
  ) => {
    try {
      setMostViewedDepartmentLoading();
      const res = await axios.get(
        `/api/v1/activities/departments/${departmentId}/screens?startDate=${startDate}&endDate=${endDate}`
      );

      dispatch({
        type: GET_MOST_VIEWED_SCREENS_DEPARTMENT_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch markers in department
  const getMarkersPanicButtonDepartment = async (
    departmentId,
    startDate,
    endDate
  ) => {
    try {
      setMarkerPanicBtnDepartmentLoading();
      const res = await axios.get(
        `/api/v1/activities/departments/${departmentId}/markers?startDate=${startDate}&endDate=${endDate}`
      );

      dispatch({
        type: GET_MARKERS_PANIC_BTN_DEPARTMENT_DASHBOARD,
        payload: res.data?.data,
      });
    } catch (err) {
      dispatch({
        type: DASHBOARD_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set activity loading
  const setActivityLoading = async () => {
    dispatch({
      type: SET_LOADING_ACTIVITY_DASHBOARD,
    });
  };

  // Set gender statistic loading
  const setGenderLoading = async () => {
    dispatch({
      type: SET_LOADING_GENDER_DASHBOARD,
    });
  };

  // Set app info loading
  const setAppLoading = async () => {
    dispatch({
      type: SET_APP_INFO_LOADING_DASHBOARD,
    });
  };

  // Set user department loading
  const setUserDepartmentLoading = async () => {
    dispatch({
      type: SET_USER_DEPARTMENT_LOADING_DASHBOARD,
    });
  };

  // Set user municipality loading
  const setUserMunicipalitiesLoading = async () => {
    dispatch({
      type: SET_USER_MUNICIPALITY_LOADING_DASHBOARD,
    });
  };

  // Set user age municipality loading
  const setUserAgeMunicipalitiesLoading = async () => {
    dispatch({
      type: SET_USER_AGE_MUNICIPALITY_LOADING_DASHBOARD,
    });
  };

  // Set user loading
  const setUserAgeLoading = async () => {
    dispatch({
      type: SET_USER_AGE_LOADING_DASHBOARD,
    });
  };

  // Set panic button pulsation loading
  const setBtnPanicPulsationsLoading = async () => {
    dispatch({
      type: SET_BTN_PANIC_PULSATIONS_LOADING_DASHBOARD,
    });
  };

  // Set most viewed loading
  const setMostViewedLoading = async () => {
    dispatch({
      type: SET_MOST_VIEWED_SCREENS_LOADING_DASHBOARD,
    });
  };

  // Set time activity department loading
  const setTimeActivityDepartmentLoading = async () => {
    dispatch({
      type: SET_TIME_ACTIVITY_DEPARTMENT_LOADING_DASHBOARD,
    });
  };

  // Set time activity department loading
  const setBtnPanicPulsationsDepartmentLoading = async () => {
    dispatch({
      type: SET_BTN_PANIC_PULSATIONS_DEPARTMENT_LOADING_DASHBOARD,
    });
  };

  // Set most viewed department loading
  const setMostViewedDepartmentLoading = async () => {
    dispatch({
      type: SET_MOST_VIEWED_SCREENS_DEPARTMENT_LOADING_DASHBOARD,
    });
  };

  // Set markers panic button department loading
  const setMarkerPanicBtnDepartmentLoading = async () => {
    dispatch({
      type: SET_MARKER_BTN_PANIC_DEPARTMENT_LOADING_DASHBOARD,
    });
  };

  const setDepartmentId = (id) => {
    dispatch({
      type: SET_DEPARTMENT_ID,
      payload: id,
    });
  };

  const setStartDateDashboard = (date) => {
    dispatch({
      type: SET_START_DATE_DASHBOARD,
      payload: date,
    });
  };

  const setEndDateDashboard = (date) => {
    dispatch({
      type: SET_END_DATE_DASHBOARD,
      payload: date,
    });
  };

  const setStartDateDepartment = (date) => {
    dispatch({
      type: SET_START_DATE_DEPARTMENT_DASHBOARD,
      payload: date,
    });
  };
  const setEndDateDepartment = (date) => {
    dispatch({
      type: SET_END_DATE_DEPARTMENT_DASHBOARD,
      payload: date,
    });
  };

  const setStartDateMarkerMap = (date) => {
    dispatch({
      type: SET_START_DATE_MARKER_MAP_DASHBOARD,
      payload: date,
    });
  };

  const setEndDateMarkerMap = (date) => {
    dispatch({
      type: SET_END_DATE_MARKER_MAP_DASHBOARD,
      payload: date,
    });
  };

  // Clear errors
  const clearErrorsDashboard = async () => {
    dispatch({
      type: CLEAR_ERRORS_DASHBOARD,
    });
  };

  // Clear data
  const clearDashboardData = async () => {
    dispatch({
      type: CLEAR_DATA_DASHBOARD,
    });
  };

  return (
    <DashboardContext.Provider
      value={{
        activities: state.activities,
        activity: state.activity,
        errors: state.errors,
        activityLoading: state.activityLoading,
        gender: state.gender,
        genderLoading: state.genderLoading,
        app: state.app,
        appLoading: state.appLoading,
        userDepartments: state.userDepartments,
        userDepartmentLoading: state.userDepartmentLoading,
        userAges: state.userAges,
        userAgeLoading: state.userAgeLoading,
        userMunicipalities: state.userMunicipalities,
        userMunicipalityLoading: state.userMunicipalityLoading,
        userAgeMunicipalities: state.userAgeMunicipalities,
        userAgeMunicipalityLoading: state.userAgeMunicipalityLoading,
        btnPanicPulsations: state.btnPanicPulsations,
        btnPanicPulsationLoading: state.btnPanicPulsationLoading,
        mostViewedScreens: state.mostViewedScreens,
        mostViewedScreenLoading: state.mostViewedScreenLoading,
        timeActivityDepartment: state.timeActivityDepartment,
        timeActivityDepartmentLoading: state.timeActivityDepartmentLoading,
        btnPanicPulsationsDepartment: state.btnPanicPulsationsDepartment,
        btnPanicPulsationDepartmentLoading:
          state.btnPanicPulsationDepartmentLoading,
        mostViewedScreensDepartment: state.mostViewedScreensDepartment,
        mostViewedScreenDepartmentLoading:
          state.mostViewedScreenDepartmentLoading,
        markersPanicBtnDepartment: state.markersPanicBtnDepartment,
        markerPanicBtnDepartmentLoading: state.markerPanicBtnDepartmentLoading,
        departmentId: state.departmentId,
        startDateDashboard: state.startDateDashboard,
        endDateDashboard: state.endDateDashboard,
        startDateDepartment: state.startDateDepartment,
        endDateDepartment: state.endDateDepartment,
        startDateMarkerMap: state.startDateMarkerMap,
        endDateMarkerMap: state.endDateMarkerMap,
        getActivities,
        getActivity,
        deleteActivity,
        deleteVisualization,
        deletePanicButtonPress,
        getUserGender,
        getAppInfo,
        getUserDepartments,
        getUserMunicipalities,
        getUserAge,
        getUserAgeMunicipalities,
        getBtnPanicPulsations,
        getMostViewedScreens,
        getTimeActivityByDepartment,
        getBtnPanicPulsationsByDepartment,
        getMostViewedScreensByDepartment,
        getMarkersPanicButtonDepartment,
        setDepartmentId,
        setStartDateDashboard,
        setEndDateDashboard,
        setStartDateDepartment,
        setEndDateDepartment,
        setStartDateMarkerMap,
        setEndDateMarkerMap,
        clearErrorsDashboard,
        clearDashboardData,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardState;
