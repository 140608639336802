import {
  GET_USER_PROCESS,
  SET_LOADING_USER_PROCESS,
  USER_PROCESS_ERROR,
  CLEAR_DATA_USER_PROCESS,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_USER_PROCESS:
      return {
        ...state,
        process: action.payload,
        loading: false,
        errors: null,
      };
    case SET_LOADING_USER_PROCESS:
      return {
        ...state,
        loading: true,
      };
    case USER_PROCESS_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_DATA_USER_PROCESS:
      return {
        ...state,
        process: {},
        loading: false,
        errors: null,
      };
    default:
      return state;
  }
};
