import {
  GET_INSTANCES,
  GET_INSTANCE,
  SET_LOADING_INSTANCE,
  INSTANCE_ERROR,
  CLEAR_ERRORS_INSTANCE,
  CLEAR_DATA_INSTANCE,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_INSTANCES:
      return {
        ...state,
        instances: action.payload,
        errors: null,
        loading: false,
      };
    case GET_INSTANCE:
      return {
        ...state,
        instance: action.payload,
        loading: false,
        errors: null,
      };
    case SET_LOADING_INSTANCE:
      return {
        ...state,
        loading: true,
      };
    case INSTANCE_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_INSTANCE:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_INSTANCE:
      return {
        ...state,
        instances: [],
        instance: {},
        loading: false,
        errors: null,
      };
    default:
      return state;
  }
};
