// Auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

// Users
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const SET_LOADING_USERS = 'SET_LOADING_USERS';
export const USERS_ERROR = 'USERS_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const CLEAR_ERRORS_USERS = 'CLEAR_ERRORS_USERS';
export const CLEAR_DATA_USERS = 'CLEAR_DATA_USERS';

// Departments
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const SET_LOADING_DEPARTMENT = 'SET_LOADING_DEPARTMENT';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';
export const CLEAR_ERRORS_DEPARTMENT = 'CLEAR_ERRORS_DEPARTMENT';
export const CLEAR_DATA_DEPARTMENT = 'CLEAR_DATA_DEPARTMENT';

// Municipalities
export const GET_MUNICIPALITIES = 'GET_MUNICIPALITIES';
export const GET_MUNICIPALITY = 'GET_MUNICIPALITY';
export const SET_LOADING_MUNICIPALITY = 'SET_LOADING_MUNICIPALITY';
export const MUNICIPALITY_ERROR = 'MUNICIPALITY_ERROR';
export const CLEAR_ERRORS_MUNICIPALITY = 'CLEAR_ERRORS_MUNICIPALITY';
export const CLEAR_DATA_MUNICIPALITY = 'CLEAR_DATA_MUNICIPALITY';

// Prevention
export const GET_PREVENTIONS = 'GET_PREVENTIONS';
export const GET_PREVENTION = 'GET_PREVENTION';
export const SET_LOADING_PREVENTION = 'SET_LOADING_PREVENTION';
export const PREVENTION_ERROR = 'PREVENTION_ERROR';
export const CLEAR_ERRORS_PREVENTION = 'CLEAR_ERRORS_PREVENTION';
export const CLEAR_DATA_PREVENTION = 'CLEAR_DATA_PREVENTION';

// Law
export const GET_LAWS = 'GET_LAWS';
export const GET_LAW = 'GET_LAW';
export const SET_LOADING_LAW = 'SET_LOADING_LAW';
export const LAW_ERROR = 'LAW_ERROR';
export const CLEAR_ERRORS_LAW = 'CLEAR_ERRORS_LAW';
export const CLEAR_DATA_LAW = 'CLEAR_DATA_LAW';

// Testimonials
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';
export const GET_TESTIMONIAL = 'GET_TESTIMONIAL';
export const SET_LOADING_TESTIMONIAL = 'SET_LOADING_TESTIMONIAL';
export const TESTIMONIAL_ERROR = 'TESTIMONIAL_ERROR';
export const CLEAR_ERRORS_TESTIMONIAL = 'CLEAR_ERRORS_TESTIMONIAL';
export const CLEAR_DATA_TESTIMONIAL = 'CLEAR_DATA_TESTIMONIAL';

// Games
export const GET_GAMES = 'GET_GAMES';
export const GET_GAME = 'GET_GAME';
export const GET_RESULTS_GAME = 'GET_RESULTS_GAME';
export const GET_RESULT_GAME = 'GET_RESULT_GAME';
export const SET_LOADING_GAME = 'SET_LOADING_GAME';
export const GAME_ERROR = 'GAME_ERROR';
export const CLEAR_ERRORS_GAME = 'CLEAR_ERRORS_GAME';
export const CLEAR_DATA_GAME = 'CLEAR_DATA_GAME';

// Instances
export const GET_INSTANCES = 'GET_INSTANCES';
export const GET_INSTANCE = 'GET_INSTANCE';
export const SET_LOADING_INSTANCE = 'SET_LOADING_INSTANCE';
export const INSTANCE_ERROR = 'INSTANCE_ERROR';
export const CLEAR_ERRORS_INSTANCE = 'CLEAR_ERRORS_INSTANCE';
export const CLEAR_DATA_INSTANCE = 'CLEAR_DATA_INSTANCE';

// Processes
export const GET_PROCESS = 'GET_PROCESS';
export const SET_LOADING_PROCESS = 'SET_LOADING_PROCESS';
export const PROCESS_ERROR = 'PROCESS_ERROR';
export const CLEAR_DATA_PROCESS = 'CLEAR_DATA_PROCESS';

// User Processes
export const GET_USER_PROCESS = 'GET_USER_PROCESS';
export const SET_LOADING_USER_PROCESS = 'SET_LOADING_USER_PROCESS';
export const USER_PROCESS_ERROR = 'USER_PROCESS_ERROR';
export const CLEAR_DATA_USER_PROCESS = 'CLEAR_DATA_USER_PROCESS';

// Dashboard
export const GET_ACTIVITIES_DASHBOARD = 'GET_ACTIVITIES_DASHBOARD';
export const GET_ACTIVITY_DASHBOARD = 'GET_ACTIVITY_DASHBOARD';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_VISUALIZATION = 'DELETE_VISUALIZATION';
export const DELETE_PANIC_BUTTON_PULSATION = 'DELETE_PANIC_BUTTON_PULSATION';
export const SET_LOADING_ACTIVITY_DASHBOARD = 'SET_LOADING_ACTIVITY_DASHBOARD';
export const GET_GENDER_DASHBOARD = 'GET_GENDER_DASHBOARD';
export const SET_LOADING_GENDER_DASHBOARD = 'SET_LOADING_GENDER_DASHBOARD';
export const GET_APP_INFO_DASHBOARD = 'GET_APP_INFO_DASHBOARD';
export const SET_APP_INFO_LOADING_DASHBOARD = 'SET_APP_INFO_LOADING_DASHBOARD';
export const GET_USERS_DEPARTMENTS_DASHBOARD =
  'GET_USERS_DEPARTMENTS_DASHBOARD';
export const SET_USER_DEPARTMENT_LOADING_DASHBOARD =
  'SET_USER_DEPARTMENT_LOADING_DASHBOARD';
export const GET_USER_MUNICIPALITIES_DASHBOARD =
  'GET_USER_MUNICIPALITIES_DASHBOARD';
export const SET_USER_MUNICIPALITY_LOADING_DASHBOARD =
  'SET_USER_MUNICIPALITY_LOADING_DASHBOARD';
export const GET_USERS_AGE_DASHBOARD = 'GET_USERS_AGE_DASHBOARD';
export const SET_USER_AGE_LOADING_DASHBOARD = 'SET_USER_AGE_LOADING_DASHBOARD';
export const GET_USER_AGE_MUNICIPALITIES_DASHBOARD =
  'GET_USER_AGE_MUNICIPALITIES_DASHBOARD';
export const SET_USER_AGE_MUNICIPALITY_LOADING_DASHBOARD =
  'SET_USER_AGE_MUNICIPALITY_LOADING_DASHBOARD';
export const GET_BTN_PANIC_PULSATIONS_DASHBOARD =
  'GET_BTN_PANIC_PULSATIONS_DASHBOARD';
export const SET_BTN_PANIC_PULSATIONS_LOADING_DASHBOARD =
  'SET_BTN_PANIC_PULSATIONS_LOADING_DASHBOARD';
export const GET_MOST_VIEWED_SCREENS_DASHBOARD =
  'GET_MOST_VIEWED_SCREENS_DASHBOARD';
export const SET_MOST_VIEWED_SCREENS_LOADING_DASHBOARD =
  'SET_MOST_VIEWED_SCREENS_LOADING_DASHBOARD';
export const GET_TIME_ACTIVITY_DEPARTMENT_DASHBOARD =
  'GET_TIME_ACTIVITY_DEPARTMENT_DASHBOARD';
export const SET_TIME_ACTIVITY_DEPARTMENT_LOADING_DASHBOARD =
  'SET_TIME_ACTIVITY_DEPARTMENT_LOADING_DASHBOARD';
export const GET_BTN_PANIC_PULSATIONS_DEPARTMENT_DASHBOARD =
  'GET_BTN_PANIC_PULSATIONS_DEPARTMENT_DASHBOARD';
export const SET_BTN_PANIC_PULSATIONS_DEPARTMENT_LOADING_DASHBOARD =
  'SET_BTN_PANIC_PULSATIONS_DEPARTMENT_LOADING_DASHBOARD';
export const GET_MOST_VIEWED_SCREENS_DEPARTMENT_DASHBOARD =
  'GET_MOST_VIEWED_SCREENS_DEPARTMENT_DASHBOARD';
export const SET_MOST_VIEWED_SCREENS_DEPARTMENT_LOADING_DASHBOARD =
  'SET_MOST_VIEWED_SCREENS_DEPARTMENT_LOADING_DASHBOARD';
export const GET_MARKERS_PANIC_BTN_DEPARTMENT_DASHBOARD =
  'GET_MARKERS_PANIC_BTN_DEPARTMENT_DASHBOARD';
export const SET_MARKER_BTN_PANIC_DEPARTMENT_LOADING_DASHBOARD =
  'SET_MARKER_BTN_PANIC_DEPARTMENT_LOADING_DASHBOARD';
export const SET_DEPARTMENT_ID = 'SET_DEPARTMENT_ID';
export const SET_START_DATE_DASHBOARD = 'SET_START_DATE_DASHBOARD';
export const SET_END_DATE_DASHBOARD = 'SET_END_DATE_DASHBOARD';
export const SET_START_DATE_DEPARTMENT_DASHBOARD =
  'SET_START_DATE_DEPARTMENT_DASHBOARD';
export const SET_END_DATE_DEPARTMENT_DASHBOARD =
  'SET_END_DATE_DEPARTMENT_DASHBOARD';
export const SET_START_DATE_MARKER_MAP_DASHBOARD =
  'SET_START_DATE_MARKER_MAP_DASHBOARD';
export const SET_END_DATE_MARKER_MAP_DASHBOARD =
  'SET_END_DATE_MARKER_MAP_DASHBOARD';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';
export const CLEAR_ERRORS_DASHBOARD = 'CLEAR_ERRORS_DASHBOARD';
export const CLEAR_DATA_DASHBOARD = 'CLEAR_DATA_DASHBOARD';

// Partner Institution
export const GET_PARTNER_INSTITUTIONS = 'GET_PARTNER_INSTITUTIONS';
export const GET_PARTNER_INSTITUTION = 'GET_PARTNER_INSTITUTION';
export const SET_LOADING_PARTNER_INSTITUTION =
  'SET_LOADING_PARTNER_INSTITUTION';
export const PARTNER_INSTITUTION_ERROR = 'PARTNER_INSTITUTION_ERROR';
export const CLEAR_ERRORS_PARTNER_INSTITUTION =
  'CLEAR_ERRORS_PARTNER_INSTITUTION';
export const CLEAR_DATA_PARTNER_INSTITUTION = 'CLEAR_DATA_PARTNER_INSTITUTION';

// Bracelet
export const BRACELET_GET_METRICS = 'BRACELET_GET_METRICS';
export const BRACELET_SET_LOADING = 'BRACELET_SET_LOADING';
export const BRACELET_ERROR = 'BRACELET_ERROR';
export const BRACELET_CLEAR_ERRORS = 'BRACELET_CLEAR_ERRORS';
export const BRACELET_CLEAR_DATA = 'BRACELET_CLEAR_DATA';

// Role Access
export const ROLE_ACCESS_LOAD_ACCESS = 'ROLE_ACCESS_LOAD_ACCESS';
export const ROLE_ACCESS_ERROR = 'ROLE_ACCESS_ERROR';
export const ROLE_ACCESS_CLEAR_DATA = 'ROLE_ACCESS_CLEAR_DATA';