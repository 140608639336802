import {
  GET_TESTIMONIALS,
  GET_TESTIMONIAL,
  SET_LOADING_TESTIMONIAL,
  TESTIMONIAL_ERROR,
  CLEAR_ERRORS_TESTIMONIAL,
  CLEAR_DATA_TESTIMONIAL,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_TESTIMONIALS:
      return {
        ...state,
        testimonials: action.payload,
        errors: null,
        loading: false,
      };
    case GET_TESTIMONIAL:
      return {
        ...state,
        testimonial: action.payload,
        loading: false,
        errors: null,
      };
    case SET_LOADING_TESTIMONIAL:
      return {
        ...state,
        loading: true,
      };
    case TESTIMONIAL_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_TESTIMONIAL:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_TESTIMONIAL:
      return {
        ...state,
        testimonials: [],
        testimonial: {},
        loading: false,
        errors: null,
      };
    default:
      return state;
  }
};
