import React, { useReducer } from 'react';
import axios from 'axios';
import { configJSON } from '../../config/requestConfig';
import InstanceContext from './instanceContext';
import instanceReducer from './instanceReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import {
  GET_INSTANCES,
  GET_INSTANCE,
  SET_LOADING_INSTANCE,
  INSTANCE_ERROR,
  CLEAR_ERRORS_INSTANCE,
  CLEAR_DATA_INSTANCE,
} from '../types';

const InstanceState = (props) => {
  const initialState = {
    instances: [],
    instance: {},
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(instanceReducer, initialState);

  // Fetch all instances
  const getInstances = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/instances');
      dispatch({
        type: GET_INSTANCES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSTANCE_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all instances with published true
  const getInstancesWithPublishedTrue = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/instances/published/');
      dispatch({
        type: GET_INSTANCES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSTANCE_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all instances with published true and current item
  const getInstancesWithPublishedTrueAndCurrentItem = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/instances/published/${id}`);
      dispatch({
        type: GET_INSTANCES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSTANCE_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch single instance
  const getInstance = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/instances/${id}`);
      dispatch({
        type: GET_INSTANCE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSTANCE_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Create a new instance
  const createInstance = async (formData, history, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.post(`/api/v1/instances`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/instances');
    } catch (error) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: INSTANCE_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update instance
  const updateInstance = async (id, formData, history, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(`/api/v1/instances/${id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/instances');
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: INSTANCE_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Update published instance
  const updatePublishedInstance = async (id, formData, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(
        `/api/v1/instances/${id}/published`,
        formData,
        configJSON
      );
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: INSTANCE_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Delete instance
  const deleteInstance = async (id, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.delete(`/api/v1/instances/${id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: INSTANCE_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch instances by department
  const getInstancesByDepartment = async (departmentId) => {
    try {
      setLoading();
      const res = await axios.get(
        `/api/v1/instances/departments/${departmentId}`
      );
      dispatch({
        type: GET_INSTANCES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSTANCE_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Create a new instance by operator
  const createInstanceByOperator = async (
    formData,
    history,
    enqueueSnackbar,
    departmentId
  ) => {
    try {
      setLoading();
      await axios.post(`/api/v1/instances`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/departments/department/instances', {
        departmentId,
      });
    } catch (error) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: INSTANCE_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update instance by operator
  const updateInstanceByOperator = async (
    id,
    formData,
    history,
    enqueueSnackbar,
    departmentId
  ) => {
    try {
      setLoading();
      await axios.put(`/api/v1/instances/${id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/departments/department/instances', {
        departmentId,
      });
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: INSTANCE_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_INSTANCE,
    });
  };

  // Clear errors
  const clearErrorsInstance = async () => {
    dispatch({
      type: CLEAR_ERRORS_INSTANCE,
    });
  };

  // Clear data
  const clearDataInstance = async () => {
    dispatch({
      type: CLEAR_DATA_INSTANCE,
    });
  };

  return (
    <InstanceContext.Provider
      value={{
        instances: state.instances,
        instance: state.instance,
        errors: state.errors,
        loading: state.loading,
        getInstances,
        getInstancesWithPublishedTrue,
        getInstancesWithPublishedTrueAndCurrentItem,
        getInstance,
        createInstance,
        updateInstance,
        updatePublishedInstance,
        deleteInstance,
        clearErrorsInstance,
        clearDataInstance,
        getInstancesByDepartment,
        createInstanceByOperator,
        updateInstanceByOperator,
      }}
    >
      {props.children}
    </InstanceContext.Provider>
  );
};

export default InstanceState;
