import React, { useReducer } from 'react';
import axios from 'axios';
import { configJSON } from '../../config/requestConfig';
import ProcessContext from './processContext';
import processReducer from './processReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import {
  GET_PROCESS,
  SET_LOADING_PROCESS,
  PROCESS_ERROR,
  CLEAR_DATA_PROCESS,
} from '../types';

const ProcessState = (props) => {
  const initialState = {
    process: {},
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(processReducer, initialState);

  // Fetch single process by user
  const getProcessByUser = async (userId) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/processes/user/${userId}`);
      dispatch({
        type: GET_PROCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PROCESS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Create a new process
  const createProcess = async (formData, history, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.post(`/api/v1/processes`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/users');
    } catch (error) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: PROCESS_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update process
  const updateProcess = async (id, formData, history, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(`/api/v1/processes/${id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/users');
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: PROCESS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_PROCESS,
    });
  };

  // Clear data
  const clearDataProcess = async () => {
    dispatch({
      type: CLEAR_DATA_PROCESS,
    });
  };

  return (
    <ProcessContext.Provider
      value={{
        process: state.process,
        errors: state.errors,
        loading: state.loading,
        getProcessByUser,
        createProcess,
        updateProcess,
        clearDataProcess,
      }}
    >
      {props.children}
    </ProcessContext.Provider>
  );
};

export default ProcessState;
