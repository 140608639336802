import {
  GET_PREVENTIONS,
  GET_PREVENTION,
  SET_LOADING_PREVENTION,
  PREVENTION_ERROR,
  CLEAR_ERRORS_PREVENTION,
  CLEAR_DATA_PREVENTION,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_PREVENTIONS:
      return {
        ...state,
        preventions: action.payload,
        errors: null,
        loading: false,
      };
    case GET_PREVENTION:
      return {
        ...state,
        prevention: action.payload,
        loading: false,
        errors: null,
      };
    case SET_LOADING_PREVENTION:
      return {
        ...state,
        loading: true,
      };
    case PREVENTION_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_PREVENTION:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_PREVENTION:
      return {
        ...state,
        preventions: [],
        prevention: {},
        loading: false,
        errors: null,
      };
    default:
      return state;
  }
};
