import React, { useReducer } from 'react';
import axios from 'axios';
import { configJSON } from '../../config/requestConfig';
import TestimonialContext from './testimonialContext';
import testimonialReducer from './testimonialReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import {
  GET_TESTIMONIALS,
  GET_TESTIMONIAL,
  SET_LOADING_TESTIMONIAL,
  TESTIMONIAL_ERROR,
  CLEAR_ERRORS_TESTIMONIAL,
  CLEAR_DATA_TESTIMONIAL,
} from '../types';

const TestimonialState = (props) => {
  const initialState = {
    testimonials: [],
    testimonial: {},
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(testimonialReducer, initialState);

  // Fetch all testimonials
  const getTestimonials = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/testimonials');
      dispatch({
        type: GET_TESTIMONIALS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TESTIMONIAL_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all testimonials with published true
  const getTestimonialsWithPublishedTrue = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/testimonials/published');
      dispatch({
        type: GET_TESTIMONIALS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TESTIMONIAL_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all testimonials with published true and current item
  const getTestimonialsWithPublishedTrueAndCurrentItem = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/testimonials/published/${id}`);
      dispatch({
        type: GET_TESTIMONIALS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TESTIMONIAL_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch single testimonial
  const getTestimonial = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/testimonials/${id}`);
      dispatch({
        type: GET_TESTIMONIAL,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TESTIMONIAL_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Create a new testimonial
  const createTestimonial = async (formData, history, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.post(`/api/v1/testimonials`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/testimonials');
    } catch (error) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: TESTIMONIAL_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update testimonial
  const updateTestimonial = async (id, formData, history, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(`/api/v1/testimonials/${id}`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/testimonials');
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: TESTIMONIAL_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Update published testimonial
  const updatePublishedTestimonial = async (id, formData, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.put(
        `/api/v1/testimonials/${id}/published`,
        formData,
        configJSON
      );
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: TESTIMONIAL_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Delete testimonial
  const deleteTestimonial = async (id, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.delete(`/api/v1/testimonials/${id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: TESTIMONIAL_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_TESTIMONIAL,
    });
  };

  // Clear errors
  const clearErrorsTestimonial = async () => {
    dispatch({
      type: CLEAR_ERRORS_TESTIMONIAL,
    });
  };

  // Clear data
  const clearDataTestimonial = async () => {
    dispatch({
      type: CLEAR_DATA_TESTIMONIAL,
    });
  };

  return (
    <TestimonialContext.Provider
      value={{
        testimonials: state.testimonials,
        testimonial: state.testimonial,
        errors: state.errors,
        loading: state.loading,
        getTestimonials,
        getTestimonialsWithPublishedTrue,
        getTestimonialsWithPublishedTrueAndCurrentItem,
        getTestimonial,
        createTestimonial,
        updateTestimonial,
        updatePublishedTestimonial,
        clearErrorsTestimonial,
        clearDataTestimonial,
        deleteTestimonial,
      }}
    >
      {props.children}
    </TestimonialContext.Provider>
  );
};

export default TestimonialState;
