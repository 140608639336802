import {
  GET_DEPARTMENTS,
  SET_LOADING_DEPARTMENT,
  DEPARTMENT_ERROR,
  CLEAR_ERRORS_DEPARTMENT,
  CLEAR_DATA_DEPARTMENT,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
        errors: null,
        loading: false,
      };
    case SET_LOADING_DEPARTMENT:
      return {
        ...state,
        loading: true,
      };
    case DEPARTMENT_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_DEPARTMENT:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_DEPARTMENT:
      return {
        ...state,
        departments: [],
        loading: false,
        errors: null,
      };
    default:
      return state;
  }
};
