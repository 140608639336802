import React, { useReducer } from 'react';
import axios from 'axios';
import { configJSON } from '../../config/requestConfig';
import MunicipalityContext from './municipalityContext';
import municipalityReducer from './municipalityReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import {
  GET_MUNICIPALITIES,
  GET_MUNICIPALITY,
  SET_LOADING_MUNICIPALITY,
  MUNICIPALITY_ERROR,
  CLEAR_ERRORS_MUNICIPALITY,
  CLEAR_DATA_MUNICIPALITY,
} from '../types';

const MunicipalityState = (props) => {
  const initialState = {
    municipalities: [],
    municipality: {},
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(municipalityReducer, initialState);

  // Fetch municipalities
  const getAllMunicipalitiesWithStateTrue = async () => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/municipalities/all/state`);
      dispatch({
        type: GET_MUNICIPALITIES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all municipalities
  const getMunicipalities = async (departmentId) => {
    try {
      setLoading();
      const res = await axios.get(
        `/api/v1/departments/${departmentId}/municipalities/`
      );
      dispatch({
        type: GET_MUNICIPALITIES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all municipalities with state true
  const getMunicipalitiesWithStateTrue = async (departmentId) => {
    try {
      setLoading();
      const res = await axios.get(
        `/api/v1/departments/${departmentId}/municipalities/state`
      );
      dispatch({
        type: GET_MUNICIPALITIES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch all municipalities with state true and current item
  const getMunicipalitiesWithStateTrueAndCurrentItem = async (
    departmentId,
    id
  ) => {
    try {
      setLoading();
      const res = await axios.get(
        `/api/v1/departments/${departmentId}/municipalities/state/${id}`
      );
      dispatch({
        type: GET_MUNICIPALITIES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch single municipality
  const getMunicipality = async (departmentId, id) => {
    try {
      setLoading();
      const res = await axios.get(
        `/api/v1/departments/${departmentId}/municipalities/${id}`
      );
      dispatch({
        type: GET_MUNICIPALITY,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Create a new municipality
  const createMunicipality = async (
    departmentId,
    formData,
    history,
    enqueueSnackbar
  ) => {
    try {
      setLoading();
      await axios.post(
        `/api/v1/departments/${departmentId}/municipalities`,
        formData,
        configJSON
      );
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push(
        '/app/administration/departments/department/municipalities',
        { id: departmentId }
      );
    } catch (error) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update municipality
  const updateMunicipality = async (
    departmentId,
    municipalityId,
    formData,
    history,
    enqueueSnackbar
  ) => {
    try {
      setLoading();
      await axios.put(
        `/api/v1/departments/${departmentId}/municipalities/${municipalityId}`,
        formData,
        configJSON
      );
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push(
        '/app/administration/departments/department/municipalities',
        { id: departmentId }
      );
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Update state municipality
  const updateStateMunicipality = async (
    departmentId,
    municipalityId,
    formData,
    enqueueSnackbar
  ) => {
    try {
      setLoading();
      await axios.put(
        `/api/v1/departments/${departmentId}/municipalities/${municipalityId}/state`,
        formData,
        configJSON
      );
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch municipalities by operator
  const getMunicipalitiesByOperator = async (departmentId) => {
    try {
      setLoading();
      const res = await axios.get(
        `/api/v1/departments/${departmentId}/municipalities/operator`
      );
      dispatch({
        type: GET_MUNICIPALITIES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_MUNICIPALITY,
    });
  };

  // Clear errors
  const clearErrorsMunicipalities = async () => {
    dispatch({
      type: CLEAR_ERRORS_MUNICIPALITY,
    });
  };

  // Clear data
  const clearDataMunicipality = async () => {
    dispatch({
      type: CLEAR_DATA_MUNICIPALITY,
    });
  };

  return (
    <MunicipalityContext.Provider
      value={{
        municipalities: state.municipalities,
        municipality: state.municipality,
        errors: state.errors,
        loading: state.loading,
        getAllMunicipalitiesWithStateTrue,
        getMunicipalities,
        getMunicipalitiesWithStateTrue,
        getMunicipalitiesWithStateTrueAndCurrentItem,
        getMunicipality,
        createMunicipality,
        updateMunicipality,
        updateStateMunicipality,
        clearErrorsMunicipalities,
        clearDataMunicipality,
        getMunicipalitiesByOperator,
      }}
    >
      {props.children}
    </MunicipalityContext.Provider>
  );
};

export default MunicipalityState;
