import {
  GET_LAWS,
  GET_LAW,
  SET_LOADING_LAW,
  LAW_ERROR,
  CLEAR_ERRORS_LAW,
  CLEAR_DATA_LAW,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_LAWS:
      return {
        ...state,
        laws: action.payload,
        errors: null,
        loading: false,
      };
    case GET_LAW:
      return {
        ...state,
        law: action.payload,
        loading: false,
        errors: null,
      };
    case SET_LOADING_LAW:
      return {
        ...state,
        loading: true,
      };
    case LAW_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_LAW:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_LAW:
      return {
        ...state,
        laws: [],
        law: {},
        loading: false,
        errors: null,
      };
    default:
      return state;
  }
};
