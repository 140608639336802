import React, { useReducer } from 'react';
import axios from 'axios';
import { configJSON } from '../../config/requestConfig';
import PartnerInstitutionContext from './partnerInstitutionContext';
import partnerInstitutionReducer from './partnerInstitutionReducer';
import {
  msgSuccessSB,
  msgFailureSB,
  optionsSuccessSB,
  optionsFailureSB,
} from '../../config/snackBar';
import {
  GET_PARTNER_INSTITUTIONS,
  GET_PARTNER_INSTITUTION,
  SET_LOADING_PARTNER_INSTITUTION,
  PARTNER_INSTITUTION_ERROR,
  CLEAR_ERRORS_PARTNER_INSTITUTION,
  CLEAR_DATA_PARTNER_INSTITUTION,
} from '../types';

const PartnerInstitutionState = (props) => {
  const initialState = {
    partnerInstitutions: [],
    partnerInstitution: {},
    loading: false,
    errors: null,
  };

  const [state, dispatch] = useReducer(partnerInstitutionReducer, initialState);

  // Fetch all partner institutions
  const getPartnerInstitutions = async () => {
    try {
      setLoading();
      const res = await axios.get('/api/v1/partner-institutions');
      dispatch({
        type: GET_PARTNER_INSTITUTIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PARTNER_INSTITUTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Fetch single partner institution
  const getPartnerInstitution = async (id) => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/partner-institutions/${id}`);
      dispatch({
        type: GET_PARTNER_INSTITUTION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PARTNER_INSTITUTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Create a new partner institution
  const createPartnerInstitution = async (
    formData,
    history,
    enqueueSnackbar
  ) => {
    try {
      setLoading();
      await axios.post(`/api/v1/partner-institutions`, formData, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/partner-institutions');
    } catch (error) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: PARTNER_INSTITUTION_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

  // Update partner institution
  const updatePartnerInstitution = async (
    id,
    formData,
    history,
    enqueueSnackbar
  ) => {
    try {
      setLoading();
      await axios.put(
        `/api/v1/partner-institutions/${id}`,
        formData,
        configJSON
      );
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
      history.push('/app/administration/partner-institutions');
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: PARTNER_INSTITUTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Update published partner institution
  const updatePublishedPartnerInstitution = async (
    id,
    formData,
    enqueueSnackbar
  ) => {
    try {
      setLoading();
      await axios.put(
        `/api/v1/partner-institutions/${id}/published`,
        formData,
        configJSON
      );
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: PARTNER_INSTITUTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Delete partner institution
  const deletePartnerInstitution = async (id, enqueueSnackbar) => {
    try {
      setLoading();
      await axios.delete(`/api/v1/partner-institutions/${id}`, configJSON);
      enqueueSnackbar(msgSuccessSB, optionsSuccessSB);
    } catch (err) {
      enqueueSnackbar(msgFailureSB, optionsFailureSB);
      dispatch({
        type: PARTNER_INSTITUTION_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_PARTNER_INSTITUTION,
    });
  };

  // Clear errors
  const clearErrorsPartnerInstitution = async () => {
    dispatch({
      type: CLEAR_ERRORS_PARTNER_INSTITUTION,
    });
  };

  // Clear data
  const clearDataPartnerInstitution = async () => {
    dispatch({
      type: CLEAR_DATA_PARTNER_INSTITUTION,
    });
  };

  return (
    <PartnerInstitutionContext.Provider
      value={{
        partnerInstitutions: state.partnerInstitutions,
        partnerInstitution: state.partnerInstitution,
        errors: state.errors,
        loading: state.loading,
        getPartnerInstitutions,
        getPartnerInstitution,
        createPartnerInstitution,
        updatePartnerInstitution,
        updatePublishedPartnerInstitution,
        deletePartnerInstitution,
        clearErrorsPartnerInstitution,
        clearDataPartnerInstitution,
      }}
    >
      {props.children}
    </PartnerInstitutionContext.Provider>
  );
};

export default PartnerInstitutionState;
