import moment from 'moment';

import {
  GET_ACTIVITIES_DASHBOARD,
  GET_ACTIVITY_DASHBOARD,
  SET_LOADING_ACTIVITY_DASHBOARD,
  GET_GENDER_DASHBOARD,
  SET_LOADING_GENDER_DASHBOARD,
  GET_APP_INFO_DASHBOARD,
  SET_APP_INFO_LOADING_DASHBOARD,
  GET_USERS_DEPARTMENTS_DASHBOARD,
  SET_USER_DEPARTMENT_LOADING_DASHBOARD,
  GET_USER_MUNICIPALITIES_DASHBOARD,
  SET_USER_MUNICIPALITY_LOADING_DASHBOARD,
  GET_USERS_AGE_DASHBOARD,
  SET_USER_AGE_LOADING_DASHBOARD,
  GET_USER_AGE_MUNICIPALITIES_DASHBOARD,
  SET_USER_AGE_MUNICIPALITY_LOADING_DASHBOARD,
  GET_BTN_PANIC_PULSATIONS_DASHBOARD,
  SET_BTN_PANIC_PULSATIONS_LOADING_DASHBOARD,
  GET_MOST_VIEWED_SCREENS_DASHBOARD,
  SET_MOST_VIEWED_SCREENS_LOADING_DASHBOARD,
  GET_TIME_ACTIVITY_DEPARTMENT_DASHBOARD,
  SET_TIME_ACTIVITY_DEPARTMENT_LOADING_DASHBOARD,
  GET_BTN_PANIC_PULSATIONS_DEPARTMENT_DASHBOARD,
  SET_BTN_PANIC_PULSATIONS_DEPARTMENT_LOADING_DASHBOARD,
  GET_MOST_VIEWED_SCREENS_DEPARTMENT_DASHBOARD,
  SET_MOST_VIEWED_SCREENS_DEPARTMENT_LOADING_DASHBOARD,
  GET_MARKERS_PANIC_BTN_DEPARTMENT_DASHBOARD,
  SET_MARKER_BTN_PANIC_DEPARTMENT_LOADING_DASHBOARD,
  SET_DEPARTMENT_ID,
  SET_START_DATE_DASHBOARD,
  SET_END_DATE_DASHBOARD,
  SET_START_DATE_DEPARTMENT_DASHBOARD,
  SET_END_DATE_DEPARTMENT_DASHBOARD,
  SET_START_DATE_MARKER_MAP_DASHBOARD,
  SET_END_DATE_MARKER_MAP_DASHBOARD,
  DASHBOARD_ERROR,
  CLEAR_ERRORS_DASHBOARD,
  CLEAR_DATA_DASHBOARD,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_ACTIVITIES_DASHBOARD:
      return {
        ...state,
        activities: action.payload,
        activityLoading: false,
        errors: null,
      };
    case GET_ACTIVITY_DASHBOARD:
      return {
        ...state,
        activity: action.payload,
        activityLoading: false,
        errors: null,
      };
    case SET_LOADING_ACTIVITY_DASHBOARD:
      return {
        ...state,
        activityLoading: true,
      };
    case GET_GENDER_DASHBOARD:
      return {
        ...state,
        gender: action.payload,
        genderLoading: false,
        errors: null,
      };
    case SET_LOADING_GENDER_DASHBOARD:
      return {
        ...state,
        genderLoading: true,
      };
    case GET_APP_INFO_DASHBOARD:
      return {
        ...state,
        app: action.payload,
        appLoading: false,
        errors: null,
      };
    case SET_APP_INFO_LOADING_DASHBOARD:
      return {
        ...state,
        appLoading: true,
      };
    case GET_USERS_DEPARTMENTS_DASHBOARD:
      return {
        ...state,
        userDepartments: action.payload,
        userDepartmentLoading: false,
        errors: null,
      };
    case SET_USER_DEPARTMENT_LOADING_DASHBOARD:
      return {
        ...state,
        userDepartmentLoading: true,
      };
    case GET_USERS_AGE_DASHBOARD:
      return {
        ...state,
        userAges: action.payload,
        userAgeLoading: false,
        errors: null,
      };
    case SET_USER_AGE_LOADING_DASHBOARD:
      return {
        ...state,
        userAgeLoading: true,
      };
    case GET_USER_MUNICIPALITIES_DASHBOARD:
      return {
        ...state,
        userMunicipalities: action.payload,
        userMunicipalityLoading: false,
        errors: null,
      };
    case SET_USER_MUNICIPALITY_LOADING_DASHBOARD:
      return {
        ...state,
        userMunicipalityLoading: true,
      };
    case GET_BTN_PANIC_PULSATIONS_DASHBOARD:
      return {
        ...state,
        btnPanicPulsations: action.payload,
        btnPanicPulsationLoading: false,
        errors: null,
      };
    case SET_BTN_PANIC_PULSATIONS_LOADING_DASHBOARD:
      return {
        ...state,
        btnPanicPulsationLoading: true,
      };
    case GET_MOST_VIEWED_SCREENS_DASHBOARD:
      return {
        ...state,
        mostViewedScreens: action.payload,
        mostViewedScreenLoading: false,
        errors: null,
      };
    case SET_MOST_VIEWED_SCREENS_LOADING_DASHBOARD:
      return {
        ...state,
        mostViewedScreenLoading: true,
      };
    case GET_USER_AGE_MUNICIPALITIES_DASHBOARD:
      return {
        ...state,
        userAgeMunicipalities: action.payload,
        userAgeMunicipalityLoading: false,
        errors: null,
      };
    case SET_USER_AGE_MUNICIPALITY_LOADING_DASHBOARD:
      return {
        ...state,
        userAgeMunicipalityLoading: true,
      };
    case GET_TIME_ACTIVITY_DEPARTMENT_DASHBOARD:
      return {
        ...state,
        timeActivityDepartment: action.payload,
        timeActivityDepartmentLoading: false,
      };
    case SET_TIME_ACTIVITY_DEPARTMENT_LOADING_DASHBOARD:
      return {
        ...state,
        timeActivityDepartmentLoading: true,
      };
    case GET_BTN_PANIC_PULSATIONS_DEPARTMENT_DASHBOARD:
      return {
        ...state,
        btnPanicPulsationsDepartment: action.payload,
        btnPanicPulsationDepartmentLoading: false,
      };
    case SET_BTN_PANIC_PULSATIONS_DEPARTMENT_LOADING_DASHBOARD:
      return {
        ...state,
        btnPanicPulsationDepartmentLoading: true,
      };
    case GET_MOST_VIEWED_SCREENS_DEPARTMENT_DASHBOARD:
      return {
        ...state,
        mostViewedScreensDepartment: action.payload,
        mostViewedScreenDepartmentLoading: false,
      };
    case SET_MOST_VIEWED_SCREENS_DEPARTMENT_LOADING_DASHBOARD:
      return {
        ...state,
        mostViewedScreenDepartmentLoading: true,
      };
    case GET_MARKERS_PANIC_BTN_DEPARTMENT_DASHBOARD:
      return {
        ...state,
        markersPanicBtnDepartment: action.payload,
        markerPanicBtnDepartmentLoading: false,
      };
    case SET_MARKER_BTN_PANIC_DEPARTMENT_LOADING_DASHBOARD:
      return {
        ...state,
        markerPanicBtnDepartmentLoading: true,
      };
    case DASHBOARD_ERROR:
      return {
        ...state,
        errors: action.payload,
        activityLoading: false,
      };
    case CLEAR_ERRORS_DASHBOARD:
      return {
        ...state,
        errors: null,
        activityLoading: false,
      };
    case SET_DEPARTMENT_ID:
      return {
        ...state,
        departmentId: action.payload,
      };
    case SET_START_DATE_DASHBOARD:
      return {
        ...state,
        startDateDashboard: action.payload,
      };
    case SET_END_DATE_DASHBOARD:
      return {
        ...state,
        endDateDashboard: action.payload,
      };
    case SET_START_DATE_DEPARTMENT_DASHBOARD:
      return {
        ...state,
        startDateDepartment: action.payload,
      };
    case SET_END_DATE_DEPARTMENT_DASHBOARD:
      return {
        ...state,
        endDateDepartment: action.payload,
      };
    case SET_START_DATE_MARKER_MAP_DASHBOARD:
      return {
        ...state,
        startDateMarkerMap: action.payload,
      };
    case SET_END_DATE_MARKER_MAP_DASHBOARD:
      return {
        ...state,
        endDateMarkerMap: action.payload,
      };
    case CLEAR_DATA_DASHBOARD:
      return {
        activities: [],
        activity: {},
        activityLoading: true,
        gender: {},
        genderLoading: true,
        app: {},
        appLoading: true,
        userDepartments: [],
        userDepartmentLoading: true,
        userAges: [],
        userAgeLoading: true,
        errors: null,
        userMunicipalities: [],
        userMunicipalityLoading: true,
        userAgeMunicipalities: [],
        userAgeMunicipalityLoading: true,
        btnPanicPulsations: {},
        btnPanicPulsationLoading: true,
        mostViewedScreens: [],
        mostViewedScreenLoading: true,
        timeActivityDepartment: {},
        timeActivityDepartmentLoading: true,
        btnPanicPulsationsDepartment: {},
        btnPanicPulsationDepartmentLoading: true,
        mostViewedScreensDepartment: [],
        mostViewedScreenDepartmentLoading: true,
        markersPanicBtnDepartment: [],
        markerPanicBtnDepartmentLoading: false,
        departmentId: null,
        startDateDashboard: moment('2022-05-14').format('YYYY-MM-DD'),
        endDateDashboard: moment().format('YYYY-MM-DD'),
        startDateDepartment: moment('2022-05-14').format('YYYY-MM-DD'),
        endDateDepartment: moment().format('YYYY-MM-DD'),
        startDateMarkerMap: moment('2022-05-14').format('YYYY-MM-DD'),
        endDateMarkerMap: moment().format('YYYY-MM-DD'),
      };
    default:
      return state;
  }
};
