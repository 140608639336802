import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import AuthContext from './authContext';
import authReducer from './authReducer';
import UserProcessContext from '../userProcess/userProcessContext';
import DashboardContext from '../dashboard/dashboardContext';
import BraceletContext from '../bracelet/braceletContext';
import RoleAccessContext from '../roleAccess/roleAccessContext';
import { configJSON } from '../../config/requestConfig';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CLEAR_AUTH_ERRORS,
} from '../types';

const cookie = new Cookies();

const AuthState = (props) => {
  const userProcessContext = useContext(UserProcessContext);
  const dashboardContext = useContext(DashboardContext);
  const braceletContext = useContext(BraceletContext);
  const roleAccessContext = useContext(RoleAccessContext);

  const { getProcessByCurrentUser, clearDataUserProcess } = userProcessContext;
  const { clearDashboardData } = dashboardContext;
  const { clearBraceletData } = braceletContext;
  const { clearRoleAccessData } = roleAccessContext

  const initialState = {
    token: cookie.get('token'),
    isAuthenticated: null,
    loading: true,
    user: null,
    errors: null,
    info: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Login user
  const login = async (formData) => {
    try {
      const res = await axios.post(
        `/api/v1/auth/login/web`,
        formData,
        configJSON
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      await loadUser();
    } catch (error) {
      dispatch({
        type: LOGIN_FAIL,
        payload: error?.response?.data?.errors,
      });
    }
  };

  // Load User
  const loadUser = async () => {
    try {
      const res = await axios.get(`/api/v1/auth/me`);

      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });

      // Load process for operator
      if (res.data?.data?.role === 'operador') {
        await getProcessByCurrentUser();
      }
    } catch (error) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // Logout
  const logout = async () => {
    try {
      await axios.get(`/api/v1/auth/logout`);
      clearDataUserProcess();
      clearDashboardData();
      clearBraceletData();
      clearRoleAccessData();

      dispatch({
        type: LOGOUT,
      });
    } catch (error) {
      dispatch({
        type: LOGOUT,
        payload: error?.response?.data?.errors,
      });
    }
  };

  // Forgot password
  const forgotPassword = async (formData) => {
    try {
      const res = await axios.post(
        '/api/v1/auth/forgotpassword',
        formData,
        configJSON
      );

      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: res.data?.data,
      });
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD_FAILURE,
        payload: error?.response?.data?.errors,
      });
    }
  };

  // Reset password
  const resetPassword = async (formData, token) => {
    try {
      const res = await axios.put(
        `/api/v1/auth/resetpassword/${token}`,
        formData,
        configJSON
      );

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.data?.data,
      });
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAILURE,
        payload: error?.response?.data?.errors,
      });
    }
  };

  // Clear errors
  const clearAuthErrors = () => {
    dispatch({
      type: CLEAR_AUTH_ERRORS,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        errors: state.errors,
        info: state.info,
        login,
        loadUser,
        logout,
        forgotPassword,
        resetPassword,
        clearAuthErrors,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
