import {
  GET_GAMES,
  GET_GAME,
  GET_RESULTS_GAME,
  GET_RESULT_GAME,
  SET_LOADING_GAME,
  GAME_ERROR,
  CLEAR_ERRORS_GAME,
  CLEAR_DATA_GAME,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_GAMES:
      return {
        ...state,
        games: action.payload,
        errors: null,
        loading: false,
      };
    case GET_GAME:
      return {
        ...state,
        game: action.payload,
        loading: false,
        errors: null,
      };
    case GET_RESULTS_GAME:
      return {
        ...state,
        answers: action.payload,
        loading: false,
        errors: null,
      };
    case GET_RESULT_GAME:
      return {
        ...state,
        answer: action.payload,
        loading: false,
        errors: null,
      };
    case SET_LOADING_GAME:
      return {
        ...state,
        loading: true,
      };
    case GAME_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case CLEAR_ERRORS_GAME:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    case CLEAR_DATA_GAME:
      return {
        ...state,
        games: [],
        game: {},
        answers: [],
        result: {},
        loading: false,
        errors: null,
      };
    default:
      return state;
  }
};
